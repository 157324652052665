/* eslint-disable react-hooks/exhaustive-deps*/

import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../context/UserContext/UserContext";

const HeaderJD = ({ title }) => {
  const { userData } = useContext(UserContext);

  return (
    <div className="flex w-full justify-between items-center pt-14 lg:pt-0">
      <h6 className="font-bold">{title}</h6>
      <Link
        to="/profil"
        className="w-12 hidden lg:flex justify-center items-center rounded-full aspect-square overflow-hidden"
      >
        <img
          src={
            (userData && window.location.origin + userData.foto_profil) ||
            `${process.env.PUBLIC_URL}/images/profile-picture.png`
          }
          onError={(event) => {
            event.target.src = `${process.env.PUBLIC_URL}/images/profile-picture.png`;
          }}
          alt="profile"
        />
      </Link>
    </div>
  );
};

export default HeaderJD;
