/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { useContext, useState } from "react";
import HeaderJD from "../components/Layout/HeaderJD";
import { useEffect } from "react";
import TryoutContext from "../context/TryoutContext/TryoutContext";
import dateToString from "../script/dateToString";
import Modal from "../components/Home/Modal";
import { Link, useNavigate } from "react-router-dom";
import {
  getPendaftaranInfo,
  getPengerjaanInfo,
  getPengumumanInfo,
  getPromoInfo,
  getVerificationInfo,
} from "../script/dateUtils";
import Price2Digit from "../components/Layout/Price2Digit";
import FormatPrice from "../components/Layout/FormatPrice";
import dayjs from "dayjs";

const TryoutRegister = ({ data, setModalContent, setOpen, addProp }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-7 gap-3 w-96">
      <div className="flex items-center gap-5">
        <p className="font-content-0 font-bold">{data.nama_tryout}</p>
        <i
          onClick={() => {
            setModalContent(
              <DetailComponent tryoutData={data} setOpen={setOpen} />
            );
            setOpen(true);
          }}
          className="fa-solid fa-circle-question text-xl text-abu hover:cursor-pointer"
        ></i>
      </div>
      <p className="font-content-1 mt-2">Kamu bisa mendaftar pada :</p>
      <div className="flex justify-center items-center px-5 py-3 bg-krem rounded-xl">
        <p className="font-content-1 font-bold text-merah">
          {addProp.openPendaftaranDateString} -{" "}
          {addProp.endPendaftaranDateString}
        </p>
      </div>
      <button
        disabled={
          addProp.currentTimestamp < addProp.openPendaftaranDateTimestamp
        }
        onClick={() => {
          if (addProp.currentTimestamp > addProp.openPendaftaranDateTimestamp) {
            if (parseInt(data.tipe) === 2)
              navigate(`/tryout/${data.id}/daftar`);
            else if (parseInt(data.tipe) === 4) {
              setModalContent(
                <DaftarTryoutEventComponent
                  tryoutData={data}
                  setOpen={setOpen}
                />
              );
              setOpen(true);
            } else {
              setModalContent(
                <DaftarTryoutComponent tryoutData={data} setOpen={setOpen} />
              );
              setOpen(true);
            }
          }
        }}
        className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
      >
        Buka
      </button>
    </div>
  );
};

const TryoutVerifikasi = ({ data, setModalContent, setOpen, addProp }) => {
  return (
    <div className="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-7 gap-3 w-96">
      <div className="flex items-center gap-5">
        <p className="font-content-0 font-bold">{data.nama_tryout}</p>
        <i
          onClick={() => {
            setModalContent(
              <DetailComponent tryoutData={data} setOpen={setOpen} />
            );
            setOpen(true);
          }}
          className="fa-solid fa-circle-question text-xl text-abu hover:cursor-pointer"
        ></i>
      </div>
      <p className="font-content-1 mt-2">
        Pendaftaran kamu sedang diverifikasi. Silakan cek kembali setelah :
      </p>
      <div className="flex justify-center items-center px-5 py-3 bg-krem rounded-xl">
        <p className="font-content-1 font-bold text-merah">
          {addProp.verifikasiDateString} WIB
        </p>
      </div>
      <button
        disabled
        className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
      >
        Buka
      </button>
    </div>
  );
};

const TryoutKerjakan = ({ data, setModalContent, setOpen, addProp }) => {
  return (
    <div className="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-7 gap-3 w-96">
      <div className="flex items-center gap-5">
        <p className="font-content-0 font-bold">{data.nama_tryout}</p>
        <i
          onClick={() => {
            setModalContent(
              <DetailComponent tryoutData={data} setOpen={setOpen} />
            );
            setOpen(true);
          }}
          className="fa-solid fa-circle-question text-xl text-abu hover:cursor-pointer"
        ></i>
      </div>
      <p className="font-content-1 mt-2">
        Kamu berhasil terdaftar dalam Try Out ini. Kamu bisa mengerjakan sebelum
        :
      </p>
      <div className="flex justify-center items-center px-5 py-3 bg-green-100 rounded-xl">
        <p className="font-content-1 font-bold text-hijau">
          {addProp.endPengerjaanDateString} WIB
        </p>
      </div>
      <Link
        to={`/tryout/${data.id}/${data.dimulai ? "lobby" : "info"}`}
        className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
      >
        Buka
      </Link>
    </div>
  );
};

const TryoutSelesai = ({ data, setModalContent, setOpen, addProp }) => {
  return (
    <div className="flex flex-col justify-between bg-white shadow-lg rounded-2xl p-7 gap-3 w-96">
      <div className="flex flex-col justify-start gap-3 w-full h-full">
        <div className="flex items-center gap-5">
          <p className="font-content-0 font-bold">{data.nama_tryout}</p>
          <i
            onClick={() => {
              setModalContent(
                <DetailComponent tryoutData={data} setOpen={setOpen} />
              );
              setOpen(true);
            }}
            className="fa-solid fa-circle-question text-xl text-abu hover:cursor-pointer"
          ></i>
        </div>

        <p className="font-content-1 mt-2">
          Try Out ini sudah selesai. Kamu bisa akses pembahasan melalui menu
          Peringkat & Pembahasan
        </p>
      </div>

      <Link
        to={`/ranking-pembahasan`}
        className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
      >
        Buka
      </Link>
    </div>
  );
};

const TryoutTidakIkut = ({ data, setModalContent, setOpen }) => {
  return (
    <div className="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-7 gap-3 w-96">
      <div className="flex items-center gap-5">
        <p className="font-content-0 font-bold">{data.nama_tryout}</p>
        <i
          onClick={() => {
            setModalContent(
              <DetailComponent tryoutData={data} setOpen={setOpen} />
            );
            setOpen(true);
          }}
          className="fa-solid fa-circle-question text-xl text-abu hover:cursor-pointer"
        ></i>
      </div>
      <p className="font-content-1 mt-2">Kamu tidak mengikuti Try Out ini.</p>
      <div className="flex justify-center items-center px-5 py-3 bg-krem rounded-xl">
        <p className="font-content-1 font-bold text-merah">
          Try Out telah berakhir
        </p>
      </div>
      <button
        disabled
        className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
      >
        Buka
      </button>
    </div>
  );
};

const TryoutPendingPartner = ({ data, setModalContent, setOpen, addProp }) => {
  return (
    <div className="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-7 gap-3 w-96">
      <div className="flex items-center gap-5">
        <p className="font-content-0 font-bold">{data.nama_tryout}</p>
        <i
          onClick={() => {
            setModalContent(
              <DetailComponent tryoutData={data} setOpen={setOpen} />
            );
            setOpen(true);
          }}
          className="fa-solid fa-circle-question text-xl text-abu hover:cursor-pointer"
        ></i>
      </div>
      <p className="font-content-1 mt-2">
        Pendaftaran berhasil. Kamu bisa mengerjakan mulai tanggal berikut atau
        hubungi pemberi kode promo:
      </p>
      <div className="flex justify-center items-center px-5 py-3 bg-krem rounded-xl">
        <p className="font-content-1 font-bold text-merah">
          {addProp.transferDateString} WIB
        </p>
      </div>
      <button
        disabled
        className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
      >
        Buka
      </button>
    </div>
  );
};

const DetailComponent = ({ tryoutData, setOpen }) => {
  const { openPendaftaranDateString, endPendaftaranDateString } =
    getPendaftaranInfo(tryoutData);
  const { endPengerjaanDateString } = getPengerjaanInfo(tryoutData, false);
  const { openPengumumanDateString } = getPengumumanInfo(tryoutData);
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <div className="flex flex-col gap-6 w-full px-10 pb-10">
        <h6 className="font-bold text-center">{tryoutData.nama_tryout}</h6>
        <div className="flex flex-col gap-2 w-full">
          <p className="font-content-1 w-full text-center">
            Periode pendaftaran
          </p>
          <p className="font-content-0 w-full text-center text-oren font-bold">
            {openPendaftaranDateString} - {endPendaftaranDateString}
          </p>
          <p className="font-content-1 w-full text-center mt-4">
            Periode pengerjaan
          </p>
          <p className="font-content-0 w-full text-center text-oren font-bold">
            {openPendaftaranDateString} - {endPengerjaanDateString}
          </p>
          <p className="font-content-1 w-full text-center mt-4">
            Pengumuman hasil final
          </p>
          <p className="font-content-0 w-full text-center text-oren font-bold">
            {openPengumumanDateString}
          </p>
        </div>
      </div>
    </>
  );
};

const DaftarTryoutComponent = ({ tryoutData, setOpen }) => {
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <div className="flex flex-col gap-6 w-full px-10 pb-10">
        <h5 className="font-bold text-center">
          Daftar Tryout <span className="text-oren font-bold">Premium</span>
        </h5>
        <div className="flex flex-col gap-2 w-full">
          <p className="font-content-1 w-full text-center">
            Dengan mendaftar Try Out premium, kamu bisa mendapatkan berbagai
            macam fasilitas Try Out.
          </p>
        </div>
        <div className="flex flex-col w-full text-center gap-3">
          <div className="flex flex-row w-full">
            <div className="flex w-1/4 flex-col items-center justify-center">
              <p className="font-subtitle font-bold">Premium</p>
              <h6 className="font-content-0 font-bold line-through">IDR 25K</h6>
              <h6 className="font-subtitle font-bold">
                IDR <Price2Digit price={tryoutData.harga} />
              </h6>
            </div>
            <div className="flex w-2/4"></div>
            <div className="flex w-1/4 flex-col items-center justify-center">
              <p
                className={`font-subtitle font-bold ${
                  parseInt(tryoutData.tipe) === 1 ? "text-abu" : ""
                }`}
              >
                Gratis
              </p>
              <h6
                className={`font-subtitle font-bold ${
                  parseInt(tryoutData.tipe) === 1 ? "text-abu" : ""
                }`}
              >
                IDR 0K
              </h6>
            </div>
          </div>

          <div className="flex flex-row w-full">
            <div className="flex w-1/4 items-center justify-center">
              <i className="fa-solid fa-star text-oren"></i>
            </div>
            <div className="flex w-2/4 font-content-0 justify-center">
              Try Out CBT dengan penilaian IRT
            </div>
            <div className="flex w-1/4 items-center justify-center">
              <i className="fa-solid fa-star text-oren"></i>
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-1/4 items-center justify-center">
              <i className="fa-solid fa-star text-oren"></i>
            </div>
            <div className="flex w-2/4 font-content-0 justify-center">
              Sertifikat nilai hasil Try Out
            </div>
            <div className="flex w-1/4 items-center justify-center">
              <i className="fa-solid fa-star text-oren"></i>
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-1/4 items-center justify-center">
              <i className="fa-solid fa-star text-oren"></i>
            </div>
            <div className="flex w-2/4 font-content-0 justify-center">
              Tanpa syarat share
            </div>
            <div className="flex w-1/4 items-center justify-center">
              <i
                className="fa-solid fa-star text-oren"
                style={{
                  color: "transparent",
                  WebkitTextStrokeWidth: "2px",
                  WebkitTextStrokeColor: "black",
                }}
              ></i>
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-1/4 items-center justify-center">
              <i className="fa-solid fa-star text-oren"></i>
            </div>
            <div className="flex w-2/4 font-content-0 justify-center">
              Akses ranking ribuan peserta Try Out
            </div>
            <div className="flex w-1/4 items-center justify-center">
              <i
                className="fa-solid fa-star text-oren"
                style={{
                  color: "transparent",
                  WebkitTextStrokeWidth: "2px",
                  WebkitTextStrokeColor: "black",
                }}
              ></i>
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-1/4 items-center justify-center">
              <i className="fa-solid fa-star text-oren"></i>
            </div>
            <div className="flex w-2/4 font-content-0 justify-center">
              Akses pembahasan soal
            </div>
            <div className="flex w-1/4 items-center justify-center">
              <i
                className="fa-solid fa-star text-oren"
                style={{
                  color: "transparent",
                  WebkitTextStrokeWidth: "2px",
                  WebkitTextStrokeColor: "black",
                }}
              ></i>
            </div>
          </div>
        </div>

        <div className="flex justify-center gap-4 w-full">
          <Link
            to={`/tryout/${tryoutData.id}/daftar`}
            state={{ tarif: true }}
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-white rounded-xl text-center"
          >
            Daftar Premium
          </Link>
          {parseInt(tryoutData.tipe) === 1 ? (
            ""
          ) : (
            <Link
              to={`/tryout/${tryoutData.id}/daftar`}
              state={{ tarif: false }}
              className="border-2 border-oren font-content-0 flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-black rounded-xl text-center"
            >
              Daftar Gratis
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

const DaftarTryoutEventComponent = ({ tryoutData, setOpen }) => {
  const { daftarTryoutEvent } = useContext(TryoutContext);
  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };
  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <div className="flex flex-col gap-6 w-full px-10 pb-10">
        <h5 className="font-bold text-center">Konfirmasi</h5>
        <div className="flex flex-col gap-2 w-full">
          <p className="font-content-0 w-full text-center">
            Apakah kamu yakin ingin mendaftar {tryoutData.nama_tryout}?
          </p>
        </div>

        <div
          className={`${alertText.show ? "flex" : "hidden"} p-4 mb-4 text-sm ${
            alertText.success
              ? "text-green-700 bg-green-100"
              : "text-red-700 bg-red-100"
          } rounded-lg`}
          role="alert"
        >
          <svg
            className="inline flex-shrink-0 mr-3 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            ></path>
          </svg>
          <div>{alertText.text}</div>
        </div>

        <div className="flex justify-center gap-4 w-100">
          <button
            onClick={async (e) => {
              e.target.disabled = true;
              hideAlert();
              const res = await daftarTryoutEvent({
                id_tryout: tryoutData.id,
              });
              if (res.data) {
                setOpen(false);
              } else {
                setErrorAlert(
                  res.display_message || "Terjadi kesalahan, coba lagi nanti."
                );
              }
              e.target.enabled = true;
            }}
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-white rounded-xl text-center"
          >
            Ya
          </button>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="border-2 border-oren font-content-0 flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-black rounded-xl text-center"
          >
            Batal
          </button>
        </div>
      </div>
    </>
  );
};

const DetailBundle = ({ data, setOpen }) => {
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <div className="flex flex-col gap-6 w-full px-10 pb-10">
        <h5 className="font-bold text-center">
          Daftar Tryout <span className="text-oren font-bold">Bundle</span>
        </h5>
        <div className="flex flex-col gap-2 w-full">
          <p className="font-content-1 w-full text-center">
            Dengan mendaftar {data.nama_bundle}, kamu bisa membuka akses{" "}
            {data.isi_bundle.length} Try Out sekaligus dengan fasilitas premium.
          </p>
        </div>
        <div className="flex flex-col w-full text-center gap-5">
          <div className="flex w-full flex-col gap-2 items-center justify-center">
            <p className="font-subtitle font-bold">
              Try Out yang Akan Kamu Dapatkan
            </p>
            <table className="w-full sm:w-96 tabel-jd">
              <thead>
                <tr>
                  <th className="p-2 font-content-1 font-bold bg-krem rounded-l-xl overflow-hidden">
                    No
                  </th>
                  <th className="p-2 font-content-1 font-bold bg-krem rounded-r-xl overflow-hidden">
                    Nama Try Out
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.isi_bundle.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="p-2 font-content-1">{index + 1}</td>
                      <td className="p-2 font-content-1">{item.nama_tryout}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="flex w-full flex-col gap-2 items-center justify-center">
            <p className="font-subtitle font-bold">Fasilitas Try Out Bundle</p>
            <div className="flex w-full sm:w-80 flex-col gap-2 items-center justify-center">
              <div className="flex flex-row w-full">
                <div className="flex w-1/6 items-center justify-center">
                  <i className="fa-solid fa-star text-oren"></i>
                </div>
                <div className="flex w-5/6 font-content-0 justify-start text-left">
                  Try Out CBT dengan penilaian IRT
                </div>
              </div>
              <div className="flex flex-row w-full">
                <div className="flex w-1/6 items-center justify-center">
                  <i className="fa-solid fa-star text-oren"></i>
                </div>
                <div className="flex w-5/6 font-content-0 justify-start text-left">
                  Bisa dikerjakan kapanpun
                </div>
              </div>
              <div className="flex flex-row w-full">
                <div className="flex w-1/6 items-center justify-center">
                  <i className="fa-solid fa-star text-oren"></i>
                </div>
                <div className="flex w-5/6 font-content-0 justify-start text-left">
                  Akses ranking ribuan peserta Try Out
                </div>
              </div>
              <div className="flex flex-row w-full">
                <div className="flex w-1/6 items-center justify-center">
                  <i className="fa-solid fa-star text-oren"></i>
                </div>
                <div className="flex w-5/6 font-content-0 justify-start text-left">
                  Akses pembahasan soal
                </div>
              </div>
              <div className="flex flex-row w-full">
                <div className="flex w-1/6 items-center justify-center">
                  <i className="fa-solid fa-star text-oren"></i>
                </div>
                <div className="flex w-5/6 font-content-0 justify-start text-left">
                  Sertifikat nilai hasil Try Out
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const BundleRegister = ({ data, setModalContent, setOpen }) => {
  return (
    <div className="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-7 gap-3 w-96">
      <div className="flex items-center gap-5">
        <p className="font-content-0 font-bold">{data.nama_bundle}</p>
        <i
          onClick={() => {
            setModalContent(<DetailBundle data={data} setOpen={setOpen} />);
            setOpen(true);
          }}
          className="fa-solid fa-circle-question text-xl text-abu hover:cursor-pointer"
        ></i>
      </div>
      <p className="font-content-1 mt-2">
        Dapetin akses{" "}
        <span className="font-bold">{data.isi_bundle.length} Try Out</span>{" "}
        dengan fitur premium yang bisa kamu kerjain kapan aja!
      </p>
      <div className="flex justify-center items-center px-5 py-3 bg-green-100 rounded-xl">
        <p className="font-content-1 font-bold text-hijau text-center">
          Harga Normal :<br />
          <FormatPrice price={data.harga} />
        </p>
      </div>
      <Link
        to={`/tryout-bundle/${data.id}/daftar`}
        className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
      >
        Beli
      </Link>
    </div>
  );
};

const BundleVerifikasi = ({ data, setModalContent, setOpen, addProp }) => {
  return (
    <div className="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-7 gap-3 w-96">
      <div className="flex items-center gap-5">
        <p className="font-content-0 font-bold">{data.nama_bundle}</p>
        <i
          onClick={() => {
            setModalContent(<DetailBundle data={data} setOpen={setOpen} />);
            setOpen(true);
          }}
          className="fa-solid fa-circle-question text-xl text-abu hover:cursor-pointer"
        ></i>
      </div>
      <p className="font-content-1 mt-2">
        Pendaftaran kamu sedang diverifikasi. Silakan cek kembali setelah :
      </p>
      <div className="flex justify-center items-center px-5 py-3 bg-krem rounded-xl">
        <p className="font-content-1 font-bold text-merah">
          {addProp.verifikasiDateString} WIB
        </p>
      </div>
      {/* <button
        disabled
        className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
      >
        Buka
      </button> */}
    </div>
  );
};

const BundleTerdaftar = ({ data, setModalContent, setOpen, addProp }) => {
  return (
    <div className="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-7 gap-3 w-96">
      <div className="flex items-center gap-5">
        <p className="font-content-0 font-bold">{data.nama_bundle}</p>
        <i
          onClick={() => {
            setModalContent(<DetailBundle data={data} setOpen={setOpen} />);
            setOpen(true);
          }}
          className="fa-solid fa-circle-question text-xl text-abu hover:cursor-pointer"
        ></i>
      </div>
      <p className="font-content-1 mt-2">
        Kamu sudah membeli bundle ini. Kamu bisa mulai mengakses{" "}
        <span
          className="text-oren cursor-pointer"
          onClick={() => {
            setModalContent(<DetailBundle data={data} setOpen={setOpen} />);
            setOpen(true);
          }}
        >
          {data.isi_bundle.length} Try Out terkait
        </span>
        .
      </p>
      <div className="flex justify-center items-center px-5 py-3 bg-green-100 rounded-xl">
        <p className="font-content-1 font-bold text-hijau">Berhasil dibeli</p>
      </div>
      {/* <button
        disabled
        className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
      >
        Buka
      </button> */}
    </div>
  );
};

const TryoutBundleLocked = ({ data }) => {
  return (
    <div className="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-7 gap-3 w-96">
      <div className="flex items-center gap-5">
        <p className="font-content-0 font-bold">{data.nama_tryout}</p>
      </div>
      <p className="font-content-1 mt-2">
        Kamu belum terdaftar dalam try out ini. Kamu bisa mendaftar try out ini
        melalui paket bundle.
      </p>
      <div className="flex justify-center items-center px-5 py-3 bg-krem rounded-xl">
        <p className="font-content-1 font-bold text-merah">Belum terdaftar</p>
      </div>
      <button
        disabled
        className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
      >
        Buka
      </button>
    </div>
  );
};

const TryoutBundleNotOpen = ({ data, addProp }) => {
  return (
    <div className="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-7 gap-3 w-96">
      <div className="flex items-center gap-5">
        <p className="font-content-0 font-bold">{data.nama_tryout}</p>
      </div>
      <p className="font-content-1 mt-2">
        Try Out ini dapat dikerjakan mulai :
      </p>
      <div className="flex justify-center items-center px-5 py-3 bg-krem rounded-xl">
        <p className="font-content-1 font-bold text-merah">
          {addProp.openPendaftaranDateString} WIB
        </p>
      </div>
      <button
        disabled
        className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
      >
        Buka
      </button>
    </div>
  );
};

const TryoutBundleKerjakan = ({ data, dataBundle }) => {
  return (
    <div className="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-7 gap-3 w-96">
      <div className="flex items-center gap-5">
        <p className="font-content-0 font-bold">{data.nama_tryout}</p>
      </div>
      <p className="font-content-1 mt-2">
        Kamu sudah terdaftar dalam {dataBundle.nama_bundle}. Kamu bisa
        mengerjakan Try Out ini kapanpun !
      </p>
      <div className="flex justify-center items-center px-5 py-3 bg-green-100 rounded-xl">
        <p className="font-content-1 font-bold text-hijau">Belum Dikerjakan</p>
      </div>
      <Link
        to={`/tryout/${data.id}/${data.dimulai ? "lobby" : "info"}`}
        className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
      >
        Buka
      </Link>
    </div>
  );
};

const TryoutBundleSelesai = ({ data, setModalContent, setOpen }) => {
  const tomorrowString = dateToString(dayjs().add(1, "day"));
  return (
    <div className="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-7 gap-3 w-96">
      <div className="flex items-center gap-5">
        <p className="font-content-0 font-bold">{data.nama_tryout}</p>
        <i
          onClick={() => {
            setModalContent(
              <DetailComponent tryoutData={data} setOpen={setOpen} />
            );
            setOpen(true);
          }}
          className="fa-solid fa-circle-question text-xl text-abu hover:cursor-pointer"
        ></i>
      </div>
      <p className="font-content-1 mt-2">
        Try Out ini sudah selesai.{" "}
        {data.hasil_tersedia
          ? "Yuk lihat hasil Try Out kamu!"
          : "Hasil akan tersedia mulai :"}
      </p>
      <div className="flex justify-center items-center px-5 py-3 bg-green-100 rounded-xl">
        <p className="font-content-1 font-bold text-hijau">
          {data.hasil_tersedia ? "Sudah dikerjakan" : tomorrowString}
        </p>
      </div>
      {data.hasil_tersedia ? (
        <Link
          to={`/ranking-pembahasan`}
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
        >
          Buka
        </Link>
      ) : (
        <button
          disabled
          className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
        >
          Buka
        </button>
      )}
    </div>
  );
};

const Tryout = () => {
  const { tryoutList, bundleList } = useContext(TryoutContext);
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);

  const [tryoutBundle, setTryoutBundle] = useState([]);

  useEffect(() => {
    if (bundleList.length && tryoutList.length) {
      let newTryoutBundle = [];
      bundleList.forEach((item) => {
        if (item.status_daftar === "TERDAFTAR") {
          item.isi_bundle.forEach((item2) => {
            newTryoutBundle.push({
              nama_bundle: item.nama_bundle,
              id_tryout: item2.id_tryout,
            });
          });
        }
      });
      newTryoutBundle = [...new Set(newTryoutBundle)];
      setTryoutBundle(newTryoutBundle);
    }
  }, [bundleList, tryoutList]);

  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD title="Tryout" />

        {/* Bundle kamu */}
        {(bundleList.length && (
          <div className="flex flex-col w-full md:w-full-10 mt-10">
            <p className="font-subtitle font-bold">
              Kerjain Try Out Fitur Lengkap Kapanpun Kamu Mau dengan Try Out
              Bundle!
            </p>
            <div className="flex gap-10 flex-wrap">
              {bundleList.map((item, index) => {
                // verifikasi
                const { verifikasiTimestamp, verifikasiDateString } =
                  getVerificationInfo(item);
                const now = dayjs().valueOf();
                if (!item.waktu_verifikasi) {
                  return (
                    <BundleRegister
                      data={item}
                      setModalContent={setModalContent}
                      setOpen={setOpen}
                      key={index}
                    />
                  );
                } else if (
                  now < verifikasiTimestamp ||
                  item.status_daftar === "VERIFIKASI"
                ) {
                  return (
                    <BundleVerifikasi
                      data={item}
                      setModalContent={setModalContent}
                      setOpen={setOpen}
                      addProp={{ verifikasiDateString }}
                      key={index}
                    />
                  );
                } else {
                  return (
                    <BundleTerdaftar
                      data={item}
                      setModalContent={setModalContent}
                      setOpen={setOpen}
                      key={index}
                    />
                  );
                }
              })}
            </div>
          </div>
        )) ||
          ""}

        {/* Tryout kamu */}
        <div className="flex flex-col w-full md:w-full-10 mt-10">
          <p className="font-subtitle font-bold">Try Out Baru Telah Hadir!</p>
          <div className="flex gap-10 flex-wrap">
            {tryoutList.map((item, index) => {
              const hasBundleAccess = tryoutBundle.find(
                (item2) => item2.id_tryout === item.id
              );
              // pendaftaran
              const {
                openPendaftaranDateString,
                openPendaftaranDateTimestamp,
                endPendaftaranDateString,
                endPendaftaranDateTimestamp,
              } = getPendaftaranInfo(item);

              // batas pengerjaan
              const { endPengerjaanDateString, endPengerjaanDateTimestamp } =
                getPengerjaanInfo(item);

              // pengumuman
              const { openPengumumanDateString, openPengumumanDateTimestamp } =
                getPengumumanInfo(item);
              // verifikasi
              const { verifikasiTimestamp, verifikasiDateString } =
                getVerificationInfo(item);

              // current timestamp
              const currentTimestamp = dayjs().valueOf();
              if (hasBundleAccess) {
                if (item.selesai_mengerjakan) {
                  return (
                    <TryoutBundleSelesai
                      data={item}
                      setModalContent={setModalContent}
                      setOpen={setOpen}
                      key={index}
                    />
                  );
                } else {
                  if (currentTimestamp < openPendaftaranDateTimestamp) {
                    return (
                      <TryoutBundleNotOpen
                        key={index}
                        data={item}
                        addProp={{ openPendaftaranDateString }}
                      />
                    );
                  } else {
                    return (
                      <TryoutBundleKerjakan
                        data={item}
                        dataBundle={hasBundleAccess}
                        key={index}
                      />
                    );
                  }
                }
              } else {
                if (parseInt(item.tipe) === 3) {
                  return <TryoutBundleLocked key={index} data={item} />;
                } else if (parseInt(item.id_partnership)) {
                  // pakai promo
                  const { transferDateString } = getPromoInfo(item);

                  if (currentTimestamp < verifikasiTimestamp) {
                    return (
                      <TryoutVerifikasi
                        key={index}
                        data={item}
                        setModalContent={setModalContent}
                        setOpen={setOpen}
                        addProp={{ verifikasiDateString }}
                      />
                    );
                  } else if (item.lunas) {
                    return (
                      <TryoutKerjakan
                        key={index}
                        data={item}
                        setModalContent={setModalContent}
                        setOpen={setOpen}
                        addProp={{ endPengerjaanDateString }}
                      />
                    );
                  } else {
                    return (
                      <TryoutPendingPartner
                        key={index}
                        data={item}
                        setModalContent={setModalContent}
                        setOpen={setOpen}
                        addProp={{ transferDateString }}
                      />
                    );
                  }
                } else {
                  if (currentTimestamp < openPendaftaranDateTimestamp) {
                    return (
                      <TryoutRegister
                        key={index}
                        data={item}
                        setModalContent={setModalContent}
                        setOpen={setOpen}
                        addProp={{
                          currentTimestamp,
                          openPendaftaranDateString,
                          endPendaftaranDateString,
                          openPendaftaranDateTimestamp,
                        }}
                      />
                    );
                  } else if (
                    currentTimestamp > openPendaftaranDateTimestamp &&
                    currentTimestamp < endPendaftaranDateTimestamp
                  ) {
                    if (!item.waktu_verifikasi) {
                      return (
                        <TryoutRegister
                          key={index}
                          data={item}
                          setModalContent={setModalContent}
                          setOpen={setOpen}
                          addProp={{
                            currentTimestamp,
                            openPendaftaranDateString,
                            endPendaftaranDateString,
                            openPendaftaranDateTimestamp,
                          }}
                        />
                      );
                    } else {
                      if (currentTimestamp < verifikasiTimestamp) {
                        return (
                          <TryoutVerifikasi
                            key={index}
                            data={item}
                            setModalContent={setModalContent}
                            setOpen={setOpen}
                            addProp={{ verifikasiDateString }}
                          />
                        );
                      } else if (parseInt(item.selesai_mengerjakan)) {
                        return (
                          <TryoutSelesai
                            key={index}
                            data={item}
                            setModalContent={setModalContent}
                            setOpen={setOpen}
                            addProp={{
                              currentTimestamp,
                              openPengumumanDateString,
                              openPengumumanDateTimestamp,
                            }}
                          />
                        );
                      } else {
                        return (
                          <TryoutKerjakan
                            key={index}
                            data={item}
                            setModalContent={setModalContent}
                            setOpen={setOpen}
                            addProp={{ endPengerjaanDateString }}
                          />
                        );
                      }
                    }
                  } else if (
                    currentTimestamp > endPendaftaranDateTimestamp &&
                    currentTimestamp < endPengerjaanDateTimestamp
                  ) {
                    if (!item.waktu_verifikasi) {
                      return (
                        <TryoutTidakIkut
                          key={index}
                          data={item}
                          setModalContent={setModalContent}
                          setOpen={setOpen}
                        />
                      );
                    } else {
                      if (currentTimestamp < verifikasiTimestamp) {
                        return (
                          <TryoutVerifikasi
                            key={index}
                            data={item}
                            setModalContent={setModalContent}
                            setOpen={setOpen}
                            addProp={{ verifikasiDateString }}
                          />
                        );
                      } else if (parseInt(item.selesai_mengerjakan)) {
                        return (
                          <TryoutSelesai
                            key={index}
                            data={item}
                            setModalContent={setModalContent}
                            setOpen={setOpen}
                            addProp={{
                              currentTimestamp,
                              openPengumumanDateString,
                              openPengumumanDateTimestamp,
                            }}
                          />
                        );
                      } else {
                        return (
                          <TryoutKerjakan
                            key={index}
                            data={item}
                            setModalContent={setModalContent}
                            setOpen={setOpen}
                            addProp={{ endPengerjaanDateString }}
                          />
                        );
                      }
                    }
                  } else {
                    if (!item.waktu_verifikasi) {
                      return (
                        <TryoutTidakIkut
                          key={index}
                          data={item}
                          setModalContent={setModalContent}
                          setOpen={setOpen}
                        />
                      );
                    } else {
                      return (
                        <TryoutSelesai
                          key={index}
                          data={item}
                          setModalContent={setModalContent}
                          setOpen={setOpen}
                          addProp={{
                            currentTimestamp,
                            openPengumumanDateString,
                            openPengumumanDateTimestamp,
                          }}
                        />
                      );
                    }
                  }
                }
              }
            })}
          </div>
        </div>
      </div>
      <Modal {...{ open, setOpen }}>
        <div className="relative text-left w-11/12 md:w-2/3 lg:w-1/2 xl:w-2/5 z-50 flex flex-col text-xl font-bold bg-white rounded-2xl">
          {modalContent}
        </div>
      </Modal>
    </>
  );
};

export default Tryout;
