/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import $ from "jquery";
import HeaderJD from "../../components/Layout/HeaderJD";
import TryoutContext from "../../context/TryoutContext/TryoutContext";
import Modal from "../../components/Home/Modal";
import { getPendaftaranInfo, getPengerjaanInfo } from "../../script/dateUtils";
import { useRef } from "react";
import dayjs from "dayjs";

const TimerSubtes = ({ timer, onTimerSelesai }) => {
  const [time, setTime] = useState({
    hour: "00",
    minute: "00",
    second: "00",
  });
  if (window.timerSubtesInterval) {
    clearInterval(window.timerSubtesInterval);
  }
  window.timerSubtesInterval = setInterval(() => {
    const now = dayjs().valueOf();
    const timeDiff = timer - now;
    if (timeDiff >= 0) {
      const hour = Math.floor(timeDiff / 1000 / 3600);
      const minute = Math.floor(timeDiff / 1000 / 60) % 60;
      const second = Math.floor(timeDiff / 1000) % 60;
      setTime({
        hour: hour >= 10 ? hour : "0" + hour,
        minute: minute >= 10 ? minute : "0" + minute,
        second: second >= 10 ? second : "0" + second,
      });
    } else {
      clearInterval(window.timerSubtesInterval);
      onTimerSelesai();
    }
  }, 1000);
  return (
    <>
      {time.hour}:{time.minute}:{time.second}
    </>
  );
};

const TryoutKerjakan = () => {
  const { tryoutId, subtes } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    tryoutList,
    bundleList,
    subtesList,
    soalList,
    jawabanList,
    getSubtes,
    getSoal,
    getJawaban,
    jawabSoal,
    finishSubtes,
    clearVariable,
  } = useContext(TryoutContext);
  const [currentTryout, setCurrentTryout] = useState({});
  const [currentSubtes, setCurrentSubtes] = useState({});
  const [currentSoal, setCurrentSoal] = useState({
    soal: {},
    index: 0,
  });
  const [currentJawaban, setCurrentJawaban] = useState([]);
  const [jawabanChanged, setJawabanChanged] = useState(false);
  const [flagList, setFlagList] = useState([]);
  const clearPageData = () => {
    clearVariable({
      soalList: [],
      jawabanList: [],
    });
    setCurrentTryout({});
    setCurrentSubtes({});
    setCurrentJawaban([]);
    setCurrentSoal({
      soal: {},
      index: 0,
    });
    setJawabanChanged(false);
    setFlagList([]);
  };
  useEffect(() => {
    if (localStorage.flagList) {
      if (localStorage.flagUrl === pathname) {
        setFlagList(
          localStorage.flagList.split(",").map((item) => parseInt(item))
        );
      } else {
        localStorage.flagUrl = pathname;
      }
    } else {
      localStorage.flagUrl = pathname;
    }
    return clearPageData;
  }, []);

  useEffect(() => {
    localStorage.flagList = flagList.join(",");
  }, [flagList]);
  useEffect(() => {
    if (tryoutList && tryoutList.length) {
      const tryout = tryoutList.find(
        (item) => String(item.id) === String(tryoutId)
      );
      if (tryout) {
        let hasBundleAccess = false;
        bundleList.forEach((item) => {
          if (item.status_daftar === "TERDAFTAR") {
            if (
              item.isi_bundle.find((item2) => item2.id_tryout === tryout.id)
            ) {
              hasBundleAccess = true;
            }
          }
        });

        const currentTimestamp = dayjs().valueOf();
        const { openPendaftaranDateTimestamp } = getPendaftaranInfo(tryout);
        const { endPengerjaanDateTimestamp } = getPengerjaanInfo(tryout);
        if (!hasBundleAccess) {
          if (parseInt(tryout.id_partnership)) {
            // pakai promo
            if (!tryout.lunas) {
              navigate("/tryout");
              return;
            }
          }

          if (
            currentTimestamp < openPendaftaranDateTimestamp ||
            currentTimestamp > endPengerjaanDateTimestamp ||
            !tryout.waktu_verifikasi
          ) {
            if (window.timerSubtesInterval) {
              clearInterval(window.timerSubtesInterval);
            }
            navigate("/tryout");
            return;
          } else if (
            tryout.waktu_verifikasi &&
            currentTimestamp < tryout.waktu_verifikasi
          ) {
            navigate("/tryout");
            return;
          } else if (!tryout.dimulai) {
            navigate(`/tryout/${tryout.id}/info`);
            return;
          } else {
            getSubtes(tryout.id);
            setCurrentTryout(tryout);
          }
        } else {
          if (!tryout.dimulai) {
            navigate(`/tryout/${tryout.id}/info`);
            return;
          } else {
            getSubtes(tryout.id);
            setCurrentTryout(tryout);
          }
        }
      } else {
        navigate("/tryout");
        return;
      }
    }
  }, [tryoutList, bundleList]);

  useEffect(() => {
    if (subtesList.length) {
      if (subtesList[0].id_tryout === currentTryout.id) {
        // filter saintek soshum
        const indexToDrop = [];
        subtesList.forEach((item, index) => {
          if (
            item.tipe_string
              .toLowerCase()
              .includes(currentTryout.klaster === 1 ? "soshum" : "saintek")
          ) {
            indexToDrop.push(index);
          }
        });
        const newSubtesList = [...subtesList];
        for (let i = indexToDrop.length - 1; i >= 0; i--) {
          const index = indexToDrop[i];
          newSubtesList.splice(index, 1);
        }

        const subtesExist = newSubtesList.find(
          (item) => String(item.subtes) === String(subtes)
        );

        if (subtesExist) {
          const now = dayjs().valueOf();
          if (
            now > parseInt(subtesExist.timer_subtes) ||
            parseInt(subtesExist.timer_subtes) === 0
          ) {
            navigate(`/tryout/${currentTryout.id}/lobby`);
            return;
          } else {
            setCurrentSubtes(subtesExist);
            getSoal({
              subtes: subtesExist.subtes,
              id_tryout: currentTryout.id,
            });
          }
        } else {
          navigate(`/tryout/${currentTryout.id}/lobby`);
          return;
        }
      }
    }
  }, [subtesList]);

  useEffect(() => {
    if (soalList.length) {
      getJawaban({
        subtes: currentSubtes.subtes,
        id_tryout: currentTryout.id,
      });
      setCurrentSoal({
        soal: soalList[0],
        index: 0,
      });
    }
  }, [soalList]);

  useEffect(() => {
    if (!currentJawaban.length && jawabanList.length) {
      const newJawabanList = [...jawabanList];
      if (newJawabanList.length < soalList.length) {
        const selisih = soalList.length - newJawabanList.length;
        for (let i = 0; i < selisih; i++) {
          newJawabanList.push("F");
        }
      }
      setCurrentJawaban(newJawabanList);
    }
  }, [jawabanList]);

  useEffect(() => {
    setJawabanChanged(true);
  }, [currentJawaban]);

  useEffect(() => {
    if (jawabanChanged && currentJawaban.length) {
      (async () => {
        await jawabSoal({
          id_tryout: currentTryout.id,
          subtes: currentSubtes.subtes,
          jawaban: currentJawaban.join(","),
        });
        setJawabanChanged(false);
      })();
    }
  }, [currentSoal]);

  // Alert
  const [alertText, setAlertText] = useState({
    show: false,
    text: "",
    success: true,
  });

  const hideAlert = () => {
    setAlertText({
      show: false,
      text: alertText.message,
      success: alertText.success,
    });
  };

  // const setSuccessAlert = (message) => {
  //   setAlertText({
  //     show: true,
  //     text: message,
  //     success: true,
  //   });
  // };
  const setErrorAlert = (message) => {
    setAlertText({
      show: true,
      text: message,
      success: false,
    });
  };

  const onClickSelesai = async (e) => {
    const button = e.target;
    button.disabled = true;
    hideAlert();
    const resJawab = await jawabSoal({
      id_tryout: currentTryout.id,
      subtes: currentSubtes.subtes,
      jawaban: currentJawaban.join(","),
    });
    if (resJawab?.success) {
      const res = await finishSubtes({
        id_tryout: currentTryout.id,
        subtes: currentSubtes.subtes,
      });

      if (!res.success) {
        setErrorAlert("Gagal mengakhiri subtes. " + res.display_message);
      } else {
        clearPageData();
        navigate(`/tryout/${currentTryout.id}/lobby`);
      }
    } else {
      setErrorAlert("Gagal mengakhiri subtes. " + resJawab?.display_message);
    }

    button.disabled = false;
  };

  const onTimerSelesai = async () => {
    await jawabSoal({
      id_tryout: currentTryout.id,
      subtes: currentSubtes.subtes,
      jawaban: currentJawaban.join(","),
    });
    const res = await finishSubtes({
      id_tryout: currentTryout.id,
      subtes: currentSubtes.subtes,
    });
    if (res.success) {
      clearPageData();
      navigate(`/tryout/${currentTryout.id}/lobby`);
    }
  };

  const [open, setOpen] = useState(false);
  const opsiJawaban = ["A", "B", "C", "D", "E"];

  const btnSelesaiRef = useRef(<></>);

  return (
    <>
      <div className="flex flex-col bg-white p-10 justify-start m-0 w-full">
        <HeaderJD title={currentSubtes.nama || ""} />

        {/* Tryout kamu */}
        <div className="flex flex-col lg:flex-row w-full mt-10 gap-5">
          <div className="flex flex-col justify-center items-center bg-white shadow-lg rounded-2xl p-7 gap-3 w-full lg:w-2/3 h-fit">
            <div
              className="flex items-center gap-3 cursor-pointer hover:text-oren transition-all self-start"
              onClick={() => {
                setFlagList((prevState) => {
                  if (prevState.includes(currentSoal.index)) {
                    return [
                      ...prevState.filter((item) => item !== currentSoal.index),
                    ];
                  } else {
                    return [...prevState, currentSoal.index];
                  }
                });
              }}
            >
              <i
                className={`fa-solid fa-flag ${
                  flagList.includes(currentSoal.index)
                    ? "text-merah"
                    : "text-black"
                }`}
              ></i>
              <p className="font-content-1">Tandai soal</p>
            </div>
            <p className="font-subtitle font-bold text-center">
              Soal No {currentSoal.index + 1}
            </p>
            <div className="container-soal ck-content relative self-start">
              <div
                dangerouslySetInnerHTML={{
                  __html: $('<div className="relative"/>')
                    .html(currentSoal.soal.soal)
                    .text()
                    .replace(/&nbsp;/g, " "),
                }}
              />
            </div>
            <div className="flex flex-row gap-4 w-full sm:w-3/4 justify-center items-center">
              {opsiJawaban.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`flex font-content-0 justify-center items-center rounded-xl aspect-video p-1 w-1/6 ${
                      currentJawaban[currentSoal.index] === item
                        ? "text-white bg-oren"
                        : "text-oren bg-white"
                    } font-bold border-2 border-oren cursor-pointer`}
                    onClick={() => {
                      const newJawaban = [...currentJawaban];
                      newJawaban[currentSoal.index] = item;
                      setCurrentJawaban(newJawaban);
                    }}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
            <div className="flex w-full justify-between gap-3">
              <button
                onClick={() =>
                  setCurrentSoal({
                    soal: soalList[currentSoal.index - 1],
                    index: currentSoal.index - 1,
                  })
                }
                disabled={currentSoal.index === 0}
                className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl text-center mt-3"
              >
                Sebelumnya
              </button>
              <button
                onClick={() =>
                  setCurrentSoal({
                    soal: soalList[currentSoal.index + 1],
                    index: currentSoal.index + 1,
                  })
                }
                disabled={currentSoal.index === soalList.length - 1}
                className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl text-center mt-3"
              >
                Selanjutnya
              </button>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center bg-white shadow-lg rounded-2xl p-7 gap-3 w-full lg:w-1/3 h-fit">
            <p className="font-subtitle font-bold text-center">Waktu</p>
            <h5 className="font-bold text-center">
              <TimerSubtes
                onTimerSelesai={onTimerSelesai}
                timer={currentSubtes.timer_subtes}
              />
            </h5>
            <div className="grid grid-cols-4 gap-2 w-full">
              {soalList.map((item, index) => {
                const sudahDijawab = opsiJawaban.includes(
                  currentJawaban[index]
                );
                return (
                  <div
                    key={index}
                    className={`${
                      currentSoal.index === index
                        ? "border-abu border-4"
                        : sudahDijawab
                        ? "border-oren border-2"
                        : "border-black border-2"
                    } ${
                      sudahDijawab
                        ? "bg-oren text-white"
                        : "bg-white text-black"
                    } aspect-square relative overflow-hidden flex justify-center items-center rounded-xl cursor-pointer`}
                    onClick={() => {
                      setCurrentSoal({ soal: item, index: index });
                    }}
                  >
                    {flagList.includes(index) ? (
                      <div className="absolute rotate-45 w-6 h-6 -top-3 -left-3 bg-merah"></div>
                    ) : null}
                    {index + 1}
                  </div>
                );
              })}
            </div>
            <button
              onClick={() => setOpen(true)}
              className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max text-white rounded-xl text-center mt-3"
            >
              Selesai
            </button>
          </div>
        </div>
      </div>
      <Modal {...{ open, setOpen }}>
        <div className="relative text-left w-11/12 md:w-2/3 lg:w-1/2 xl:w-2/5 z-50 flex flex-col text-xl font-bold bg-white rounded-2xl">
          <div className="flex w-full justify-end p-7 pb-0">
            <i
              onClick={() => setOpen(false)}
              className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
            ></i>
          </div>
          <div className="flex flex-col gap-6 w-full px-10 pb-10">
            <h5 className="font-bold text-center">Konfirmasi</h5>
            <div className="flex flex-col gap-2 w-full">
              <p className="font-content-0 w-full text-center">
                Apakah kamu yakin ingin mengakhiri subtes sekarang ?
              </p>
            </div>

            <div
              className={`${
                alertText.show ? "flex" : "hidden"
              } p-4 mb-4 text-sm ${
                alertText.success
                  ? "text-green-700 bg-green-100"
                  : "text-red-700 bg-red-100"
              } rounded-lg`}
              role="alert"
            >
              <svg
                className="inline flex-shrink-0 mr-3 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <div>{alertText.text}</div>
            </div>

            <div className="flex justify-center gap-4 w-100">
              <button
                ref={btnSelesaiRef}
                onClick={onClickSelesai}
                className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-white rounded-xl text-center"
              >
                Ya
              </button>
              <button
                onClick={() => setOpen(false)}
                type="button"
                className="border-2 border-oren font-content-0 flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-black rounded-xl text-center"
              >
                Batal
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TryoutKerjakan;
