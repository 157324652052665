import { getPengumumanInfo } from "./dateUtils";
import dayjs from "dayjs";

export const isAllowedAccessPembahasan = (tryout, bundleList) => {
  let hasBundleAccess = tryout.pembayaran?.includes("bundle");
  bundleList.forEach((item) => {
    if (item.status_daftar === "TERDAFTAR") {
      if (item.isi_bundle.find((item2) => item2.id_tryout === tryout.id)) {
        hasBundleAccess = true;
      }
    }
  });
  const currentTimestamp = dayjs().valueOf();

  const { openPengumumanDateTimestamp } = getPengumumanInfo(tryout);

  if (!hasBundleAccess) {
    if (tryout.hasil_harian) {
      return !!tryout.hasil_tersedia;
    } else {
      if (currentTimestamp < openPengumumanDateTimestamp) {
        return false;
      } else {
        if (!tryout.pembayaran || tryout.pembayaran === "Gratis") {
          return false;
        } else {
          return true;
        }
      }
    }
  } else {
    return !!tryout.hasil_tersedia;
  }
};
