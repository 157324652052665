import axios from "axios";
import { useNavigate } from "react-router-dom";
import GlobalContext from "./GlobalContext";
import { host } from "../config";
import { useEffect, useReducer } from "react";
import GlobalReducer from "./GlobalReducer";
import { Helmet } from "react-helmet";
import Spinner from "../../components/Layout/Spinner";

const GlobalState = ({ children }) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(GlobalReducer, {
    totalAkun: 0,
    provinsiList: [],
    sekolahList: [],
    univList: [],
    jurusanList: [],
    tryoutList: [],
    webEventInfo: null,
    webEventInfoLoading: true,
  });

  const getProvinsiList = async () => {
    try {
      const res = await axios.get(host + "/home/get_provinsi.php");
      if (res.data.success) {
        dispatch({
          type: "getProvinsiList",
          payload: { provinsiList: res.data.data },
        });
      } else {
        console.log(res.data.message);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const getSekolahList = async (provinsi) => {
    const data = {
      provinsi: provinsi,
    };
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        host + "/home/get_sekolah.php",
        data,
        config
      );
      if (res.data.success) {
        dispatch({
          type: "getSekolahList",
          payload: { sekolahList: res.data.data },
        });
      } else {
        console.log(res.data.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const register = async (data) => {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    try {
      const res = await axios.post(host + "/auth/register.php", data, config);
      if (res.data.success) {
        return { success: true };
      } else {
        console.log(res.data.message);
        return { ...res.data, success: false };
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const login = async (data) => {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    try {
      const res = await axios.post(host + "/auth/login.php", data, config);
      if (res.data.success) {
        localStorage.token = res.data.token;
        return { ...res.data, success: true };
      } else {
        console.log(res.data.message);
        return { ...res.data, success: false };
      }
    } catch (err) {
      console.log(err);
      return { success: false };
    }
  };

  const loginWithGoogle = async (user) => {
    const data = { email: user.email, foto_profil: user.photoURL };
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        host + "/auth/login_google.php",
        data,
        config
      );
      if (res.data.success) {
        localStorage.token = res.data.token;
        return { success: true };
      } else {
        console.log(res.data.message);
        return { ...res.data, success: false };
      }
    } catch (err) {
      console.log(err);
      return { success: false };
    }
  };

  const registerGoogle = async (data) => {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        host + "/auth/register_google.php",
        data,
        config
      );
      if (res.data.success) {
        return { success: true };
      } else {
        console.log(res.data.message);
        return { ...res.data, success: false };
      }
    } catch (err) {
      console.log(err);
      return { success: false };
    }
  };

  const getUnivList = async () => {
    try {
      const res = await axios.get(host + "/jurusan/get_univ.php");
      if (res.data.success) {
        dispatch({ type: "getUnivList", payload: { univList: res.data.data } });
      } else {
        console.log(res.data.message);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const getJurusanList = async (univ, klaster) => {
    const data = {
      univ,
      klaster,
    };
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        host + "/jurusan/get_jurusan.php",
        data,
        config
      );
      if (res.data.success) {
        dispatch({
          type: "getJurusanList",
          payload: { jurusanList: res.data.data },
        });
      } else {
        console.log(res.data.message);
        dispatch({ type: "getJurusanList", payload: { jurusanList: [] } });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getTryoutList = async () => {
    try {
      const res = await axios.get(host + "/home/get_tryout.php");
      if (res.data.success) {
        dispatch({
          type: "getTryoutList",
          payload: { tryoutList: res.data.data },
        });
      } else {
        console.log(res.data.message);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const getTotalAkun = async () => {
    try {
      const res = await axios.get(host + "/home/get_total_akun.php");
      if (res.data.success) {
        dispatch({
          type: "getTotalAkun",
          payload: { totalAkun: parseInt(res.data.data) },
        });
      } else {
        console.log(res.data.message);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const getWebEventInfo = async () => {
    try {
      const res = await axios.get(host + "/home/get_web_event_info.php");
      if (res.data.success) {
        dispatch({
          type: "getWebEventInfo",
          payload: {
            webEventInfo: res.data.data,
            webEventInfoLoading: false,
          },
        });
      } else {
        console.log(res.data.message);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getWebEventInfo();
  }, []);
  useEffect(() => {
    if (state.webEventInfo) {
      const colorTheme = JSON.parse(state.webEventInfo.color_theme);
      document.documentElement.style.setProperty(
        "--primary-color",
        colorTheme.primary_color
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        colorTheme.secondary_color
      );
    } else {
      document.documentElement.style.setProperty("--primary-color", "#F09124");
      document.documentElement.style.setProperty(
        "--secondary-color",
        "#FFECC9"
      );
    }
  }, [state.webEventInfo]);

  return (
    <GlobalContext.Provider
      value={{
        provinsiList: state.provinsiList,
        sekolahList: state.sekolahList,
        univList: state.univList,
        jurusanList: state.jurusanList,
        tryoutList: state.tryoutList,
        totalAkun: state.totalAkun,
        webEventInfo: state.webEventInfo,
        webEventInfoLoading: state.webEventInfoLoading,
        getProvinsiList,
        getSekolahList,
        register,
        login,
        loginWithGoogle,
        registerGoogle,
        navigate,
        getUnivList,
        getJurusanList,
        getTryoutList,
        getTotalAkun,
        getWebEventInfo,
      }}
    >
      <Helmet>
        {/* <meta
          name="description"
          content="Try Out SNBT | Solusi tepat masuk kampus impian kamu"
        />
        <meta property="og:title" content="Jalur Dalam | Try Out SNBT" />
        <meta property="og:url" content="https://jalurdalam.com/" />
        <meta
          property="og:description"
          content="Solusi tepat masuk kampus impian kamu!"
        />
        <meta
          property="og:image"
          content="https://jalurdalam.com/app/images/logo-jd-dark-bg.png"
        /> */}
        {/* <link
          rel="apple-touch-icon"
          href={`${process.env.PUBLIC_URL}/logo192.png`}
        /> */}

        <link
          rel="icon"
          {...(state.webEventInfo ? { type: "image/png" } : {})}
          href={
            state.webEventInfoLoading
              ? ""
              : state.webEventInfo
              ? state.webEventInfo?.logo_url?.[0] == "/"
                ? process.env.PUBLIC_URL + state.webEventInfo?.logo_url
                : state.webEventInfo?.logo_url
              : `${process.env.PUBLIC_URL}/favicon.ico`
          }
        />
        <title>
          {state.webEventInfoLoading
            ? ""
            : state.webEventInfo
            ? state.webEventInfo.nama_platform
            : "Jalur Dalam | Try Out SNBT"}
        </title>
      </Helmet>
      {state.webEventInfoLoading ? (
        <div className="w-screen h-screen fixed top-0 left-0 justify-center items-center bg-white z-50">
          <Spinner />
        </div>
      ) : null}
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalState;
